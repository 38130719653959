import { useContext } from "react";
import { useSettings } from '../contexts/SettingsContext'

import { Link } from "react-router-dom";
// import Navbar from "../components/Navbar";
import { Form, Card, Button } from "react-bootstrap";
import { useTheme } from "../components/ThemeProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const Settings = () => {
  const { settings, updateSettings } = useSettings();
  const { theme, toggleTheme } = useTheme();

  const handleThemeChange = (e) => {
    toggleTheme(e.target.value);
  };


  const handleUncompletedTasksToggle = () => {
    updateSettings({ enableUncompletedTasks: !settings.enableUncompletedTasks });
  };


  return (
    <div className={`themed-background ${theme}`}>
      {/* <Navbar /> */}
      <div className="container mt-4">
        <Link to="/Journal" className="d-inline-flex align-items-center mb-3 themed-text text-decoration-none">
          <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
          Back to Journal
        </Link>
        <h1 className="mb-4 themed-text">Settings</h1>
        <div className="row">
          <div className="col-md-6 mb-4">
            <Card className="themed-card mb-3">
              <Card.Header className="themed-card-header">
                <h2 className="h5 mb-0 themed-text">General</h2>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Form.Group controlId="themeSelect">
                    <Form.Label className="themed-text">Theme</Form.Label>
                    <Form.Select
                      value={theme}
                      onChange={handleThemeChange}
                      className="themed-select"
                    >
                      <option value="light">Light</option>
                      <option value="dark">Dark</option>
                      <option value="dark2">Dark 2</option>
                    </Form.Select>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>

            <Card className="themed-card mb-3">
              <Card.Header className="themed-card-header">
                <h2 className="h5 mb-0 themed-text">Knowledge Graph</h2>
              </Card.Header>
              <Card.Body>
                <p className="themed-text">
                  Knowledge Graph settings coming soon.
                </p>
              </Card.Body>
            </Card>

            <Card className="themed-card mb-3">
              <Card.Header className="themed-card-header">
                <h2 className="h5 mb-0 themed-text">Documents</h2>
              </Card.Header>
              <Card.Body>
                <p className="themed-text">
                  Documents settings coming soon.
                </p>
              </Card.Body>
            </Card>

            <Card className="themed-card mb-3">
              <Card.Header className="themed-card-header">
                <h2 className="h5 mb-0 themed-text">Tasks</h2>
              </Card.Header>
              <Card.Body>
                <p className="themed-text">
                  Tasks settings coming soon.
                </p>
              </Card.Body>
            </Card>
          </div>

          <div className="col-md-6 mb-4">
            <Card className="themed-card mb-3">
              <Card.Header className="themed-card-header">
                <h2 className="h5 mb-0 themed-text">Journal</h2>
              </Card.Header>
              <Card.Body>
                <div>
                  <label>
                    <input
                      type="checkbox"
                      checked={settings.enableUncompletedTasks}
                      onChange={handleUncompletedTasksToggle}
                      style={{ marginRight: '8px' }}
                    />
                    Enable Uncompleted Tasks Feature
                  </label>
                </div>
              </Card.Body>
            </Card>

            <Card className="themed-card mb-3">
              <Card.Header className="themed-card-header">
                <h2 className="h5 mb-0 themed-text">OctoNix</h2>
              </Card.Header>
              <Card.Body>
                <p className="themed-text">OctoNix settings coming soon.</p>
              </Card.Body>
            </Card>

            <Card className="themed-card mb-3">
              <Card.Header className="themed-card-header">
                <h2 className="h5 mb-0 themed-text">Email</h2>
              </Card.Header>
              <Card.Body>
                <p className="themed-text">
                  <Button variant="outline-primary">
                    Add Account (TBI)
                  </Button>
                </p>
              </Card.Body>
            </Card>

            <Card className="themed-card mb-3">
              <Card.Header className="themed-card-header">
                <h2 className="h5 mb-0 themed-text">Databases</h2>
              </Card.Header>
              <Card.Body>
                <p className="themed-text">Database settings here.</p>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;